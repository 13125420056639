<script setup lang="ts"/>

<template>
  <div class="locale-changer">
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.code">
        {{ lang.text }}
				<v-img src="cz.png" />
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "locale-changer",
  data() {
    return {
      langs: [
        { code: "cs", text: "česky" },
        { code: "en", text: "English" },
        // { code: "ja", text: "日本" },
      ],
    };
  },
};
</script>

<style>
.locale-changer {
  position: fixed;
  z-index: 9999;
  top: 15px;
  left: 55px;
	background: white;
	border: solid 2px lightgray;
	text-align: center;
}
</style>

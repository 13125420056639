<template>
  <div class="credits-logo">
    <a href="https://unob.cz/" target="_blank"><img src="../assets/uo.svg"></a>
    <a href="https://vut.cz/" target="_blank"><img src="../assets/vut.svg"></a>
  </div>
</template>

<script>
export default {
  name: 'ProjectCredits',
}
</script>

<style>
.credits-logo img {
  height: 80px;
}
</style>






<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="auto"
    >
      <v-card>
        <v-card-title class="text-h5">{{ $t("shelter.removeShelter") }} {{name}}</v-card-title>
        <v-card-text>
          {{ $t("shelter.removeQuestion", { name: name }) }}
        </v-card-text>
        <v-card-actions>
          <v-btn variant="tonal" color="primary" @click="deleteShelter">{{ $t("shelter.delete") }}</v-btn>
          <v-btn color="primary" @click="dialog = false">{{ $t("shelter.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {useShelterStore} from "@/stores/shelterStore";
export default defineComponent({
  data() {
    return {
      dialog: false,
      id: null,
      name: null
    }
  },
  methods: {
    showDialog(id, name) {
      this.$data.id = id
      this.$data.name = name
      this.$data.dialog = true
    },
    deleteShelter() {
      if (this.$data.id) {
        useShelterStore().deleteShelter(this.$data.id)
      }
      this.$data.dialog = false
    }
  }
})
</script>

import { createApp } from 'vue'
//import { VueRecaptchaPlugin } from 'vue-recaptcha';
import App from './App.vue'
import router from '@/routes/index'
import axios from "axios";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { createPinia } from "pinia";
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// https://stackoverflow.com/questions/57053728/vuetify-icon-not-showing
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files


const vuetify = createVuetify({
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
            fa,
        },
    },
    components,
    directives,
})
const pinia = createPinia()
const app = createApp(App)

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ?
    'http://localhost:8000/' :
    'https://civildefense.fit.vutbr.cz:8000'

app.use(vuetify)
app.use(pinia)

/*
app.use(VueRecaptchaPlugin, {
    v2SiteKey: '6Ldm_GwnAAAAAC35a_IvUV8NVspJ4AXEQHMvuxFp'
})
*/

import {useAuthStore} from "@/stores/authStore";

// https://stackoverflow.com/questions/52653337/vuejs-redirect-from-login-register-to-home-if-already-loggedin-redirect-from
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!await useAuthStore().isAuthenticated()) {

            next({ name: 'Login' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

/*
axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            useAuthStore().logOut();
            return router.push('/login')
        }
    }
});
*/

import * as VueI18n from 'vue-i18n'

import ja from "./locales/ja.json";
import en from "./locales/en.json";
import cs from "./locales/cs.json";

const i18n = VueI18n.createI18n({
  locale: 'cs', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: { cs, en, ja },
})

app.use(router)
app.use(i18n)
app.mount('#app')

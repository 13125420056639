<template>
  <div class="text-center">
    <v-dialog v-model="show" width="1024" persistent>
      <v-card>
        <v-form fast-fail @submit.prevent="submit">
          <v-card-title v-if="false">
            <span class="text-h5" v-if="!shelter_id">{{ $t("shelter.addShelter") }}</span>
						<span class="text-h5" v-else>{{ $t("shelter.editShelter") }} {{shelter_id}}</span>
          </v-card-title>
					<v-card-subtitle>
						<span class="text-h6">Adresný bod a poloha ukrytí</span>
					</v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field :label="$t('shelter.shelterName')" :rules="rules" v-model="form.name" required></v-text-field>
                </v-col>
								<v-col cols="12" sm="6">
									<v-text-field :label="$t('shelter.longitude')" suffix="°" v-model="form.x" readonly></v-text-field>
								</v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :label="$t('shelter.latitude')" suffix="°" v-model="form.y" readonly></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
					<v-card-subtitle>
						<span class="text-h6">Určení typu stavby a ochranného prostoru</span>
					</v-card-subtitle>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" sm="4">
									<v-select label="Typ stavby" :items="buildingTypesItems" item-text="caption" item-title="caption" item-value="id" v-model="form.buildingType"></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select label="Specifikace stavby" :items="buildingSubTypesItems" item-text="caption" item-title="caption" item-value="id" v-model="form.buildingSubType"></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select label="Ochranný typ" :items="[1, 2, 3, 4]" v-model="form.type"></v-select>
								</v-col>
								<v-col cols="12" sm="3">
									<v-text-field label="Délka prostoru" suffix="m" v-model="form.depth" type="number"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
									<v-text-field label="Šířka prostoru" suffix="m" v-model="form.width" type="number"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
									<v-text-field label="Výška prostoru" suffix="m" v-model="form.height" type="number"></v-text-field>
								</v-col>
								<v-col cols="12" sm="3">
									<v-text-field label="Tloušťka obálky" suffix="cm" v-model="form.thickness" type="number"></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-subtitle>
						<span class="text-h6">Materiál ochranné obálky objektu</span>
					</v-card-subtitle>
					<v-card-text>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6">
									<v-select label="Typ materiálu" :items="materialTypesItems" item-text="caption" item-title="caption" item-value="id" v-model="form.materialType"></v-select>
								</v-col>
								<v-col cols="12" sm="6">
									<v-select label="Specifikace materiálu" :items="materialSubTypesItems" item-text="caption" item-title="caption" item-value="id" v-model="form.materialSubType"></v-select>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
          <v-card-actions>
						<small>{{ $t("shelter.notice") }}</small>
            <v-spacer></v-spacer>
            <v-btn
                type="submit"
                color="blue-darken-1"
            >
              {{ $t("shelter.save") }}
            </v-btn>
            <v-btn
                color="blue-darken-1"
                @click="show = false"
            >{{ $t("shelter.cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {useShelterStore} from "@/stores/shelterStore";
import {nextTick} from "vue";

export default {
  name: "addShelterDialog",
  data () {
    return {
      show: false,
			shelter_id: null,
      form: {
        name: null,
        x: null,
        y: null,
				buildingType: null,
				buildingSubType: null,
				materialType: null,
				materialSubType: null,
				width: null,
				height: null,
				depth: null,
				thickness: null,
				type: null,
			},
			buildingTypesItems: null,
			materialTypesItems: null,
      rules: [
        value => {
          if (value) return true
          return false
        }
      ]
    }
  },
	watch: {
		'form.buildingType': function() {
			this.$data.form.buildingSubType = null
		},
		'form.materialType': function() {
			this.$data.form.materialSubType = null
		},
	},
  methods: {
    async showDialog(coords) {
			if(coords.id) {
				this.$data.shelter_id = coords.id
				let info = useShelterStore().$state.shelters.find(t => t.id == coords.id)
				this.$data.form.name = info.address
				this.$data.form.x = info.x
				this.$data.form.y = info.y
				this.$data.form.buildingType = info.building_subtype.building_type.id
				nextTick(() => {
					this.$data.form.buildingSubType = info.building_subtype.id
				})
				this.$data.form.width = info.protectivespaces[0].width
				this.$data.form.height = info.protectivespaces[0].height
				this.$data.form.depth = info.protectivespaces[0].depth
				this.$data.form.type = info.protectivespaces[0].type
				this.$data.form.thickness = info.protectivespaces[0].thickness
				this.$data.form.materialType = info.protectivespaces[0].material_subtype.material_type.id
				nextTick(() => {
					this.$data.form.materialSubType = info.protectivespaces[0].material_subtype.id
				})
			}
			else {
				this.$data.shelter_id = null
				this.$data.form.name = null
				this.$data.shelter_id = null
				this.$data.form.y = coords.lng
				this.$data.form.x = coords.lat
			}
			if(coords.label) {
				this.$data.form.name = coords.label.toString()
			}
      this.$data.show = true
    },
    async submit() {
      if(this.$data.form.name) {
				this.$data.show = false
				if(!this.$data.shelter_id) {
					await useShelterStore().createShelter(this.$data.form)
				}
				else {
					await useShelterStore().updateShelter(this.$data.form, this.$data.shelter_id)
				}
      }
      this.$data.form.name = null
    }
  },
	computed: {
		buildingSubTypesItems() {
			if(this.$data.form.buildingType) {
				return this.$data.buildingTypesItems.find(t => t.id == this.$data.form.buildingType).buildingsubtypes
			}
			else return new Array()
		},
		materialSubTypesItems() {
			if(this.$data.form.materialType) {
				return this.$data.materialTypesItems.find(t => t.id == this.$data.form.materialType).materialsubtypes
			}
			else return new Array()
		},
	},
	async mounted() {
		await useShelterStore().initBuildingProperties()
		this.$data.buildingTypesItems = useShelterStore().$state.buildingTypes
		this.$data.materialTypesItems = useShelterStore().$state.materialTypes

		this.$watch('form.buildingType', function() {
			this.$data.buildingSubTypesItems = this.$data.buildingTypesItems.find(t => t.id == this.$data.form.buildingType).buildingsubtypes
		}, {
			deep: true
		})
	}
}
</script>
